/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        this.setupDropdownMenus();
        this.setupHomepage();
      },

      // converts wordpress menus to bootstraps's dropdowns
      setupDropdownMenus: function(){

        var dropdown = $(".menu-item-has-children");
        dropdown.addClass("dropdown");

        var link = $(".menu-item-has-children > a");
        link.append("<span class='caret'></span>");
        link.addClass("dropdown-toggle");

        link.attr("href", "#");
        link.attr("data-toggle", "dropdown");
        link.attr("role", "button");
        link.attr("aria-haspopup", "true");
        link.attr("aria-expanded", "false");

        $(".menu-item-has-children > ul").addClass("dropdown-menu");
      },

      setupHomepage: function(){

        function rowOneHeightFix(){
          if(window.innerWidth < 992){ return; }
          var rightHeight = $('.first.row .right .card__container').outerHeight();
          var leftHeight = $('.first.row .left img').outerHeight();

          if (leftHeight > rightHeight){
            $('.first.row .right').css('height', leftHeight);
          } else {
            $('.first-row .left img').css('height', rightHeight);
          }

          console.log(leftHeight, rightHeight);
        }

        function rowTwoHeightFix(){
          var leftImg = $('.second.row .left.item .background img').height();
          var rightImg = $('.second.row .right.item .background img').height();

          if(rightImg > leftImg){
            $('.second.row .right.item .background').css('height', leftImg);
          } else {
            $('.second.row .left.item .background').css('height', rightImg);
          }
        }

        function rowFourHeightFix(){
          var minHeight = 1000000;
          $('.fourth.row .item').each(function(){
            var h = $(this).find('.background img').innerHeight();
            if (h < minHeight){ minHeight = h; }
            //console.log(h);
          });
          $('.fourth.row .item').each(function(){
            $(this).find('.background').css('height', minHeight);
          });
        }

        function backgroundImgFix(){
          function thirdRow(){
            var cHeight = $('.third.row').innerHeight();
            var cWidth = $('.third.row').innerWidth();
            var cRatio = cHeight/cWidth;
            var iHeight = $('.third.row .article__background img').innerHeight();
            var iWidth = $('.third.row .article__background img').innerWidth();
            var iRatio = iHeight/iWidth;

            if(iRatio > cRatio){
              $('.third.row .article__background img').css({
                height: 'auto',
                width: '100%'
              });
            } else {
              $('.third.row .article__background img').css({
                height: '100%',
                width: 'auto'
              });
            }

            //console.log("container", cHeight, cWidth, cRatio);
            //console.log("image", iHeight, iWidth, iRatio);
          }
          function fifthRow(){
            var cHeight = $('.fifth.row').innerHeight();
            var cWidth = $('.fifth.row').innerWidth();
            var cRatio = cHeight/cWidth;
            var iHeight = $('.fifth.row .background').innerHeight();
            var iWidth = $('.fifth.row .background').innerWidth();
            var iRatio = iHeight/iWidth;

            if(iRatio > cRatio){
              $('.fifth.row .background').css({
                height: 'auto',
                width: '100%'
              });
            } else {
              $('.fifth.row .background').css({
                height: '100%',
                width: 'auto'
              });
            }

            //console.log("container", cHeight, cWidth, cRatio);
            //console.log("image", iHeight, iWidth, iRatio);
          }

          thirdRow();
          fifthRow();
        }

        if($('.home').length){
          $(window).load(function(){
            rowOneHeightFix();
            rowTwoHeightFix();
            rowFourHeightFix();
            backgroundImgFix();
          });
          $(window).resize(function(){
            rowOneHeightFix();
            rowTwoHeightFix();
            rowFourHeightFix();
            backgroundImgFix();
          });
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
